










































import { Observer } from 'mobx-vue'
import { Component, Vue, Inject } from 'vue-property-decorator'
import { StakingViewModel } from '../viewmodels/staking-viewmodel'
import { NO_TIER } from '@/constants'

@Observer
@Component({})
export default class ApyConfigDialog extends Vue {
  @Inject() vm!: StakingViewModel
  noTierText = NO_TIER
}
